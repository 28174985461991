.innerShadow {
  -webkit-box-shadow: inset 0px 6px 28px #1b2d490f;
  -moz-box-shadow: inset 0px 6px 28px #1b2d490f;
  box-shadow: inset 0px 6px 28px #1b2d490f;
}

.customScrollBar::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.customScrollBar::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background-color: #0eb948;
  border-radius: 22px;
}

.sidebarTransition {
  transition: transform 0.3s ease;
}
