.account_icon {
  color: white;
  transition: color 0.7s ease;
}

.account_icon path {
  stroke: #1f2a3b;
}

.account_icon circle:nth-child(2) {
  stroke: #1f2a3b;
}

.account_icon circle:nth-child(1) {
  stroke: #d0d5db;
}

.account_icon:hover circle,
.account_icon:hover path {
  stroke: white;
  stroke-width: 2.2;
}

.account_icon:hover {
  color: #22cb5b;
}

/* wishlist icon style */

.wishlist_icon {
  color: white;
  transition: color 0.7s ease;
}

.wishlist_icon:hover {
  color: #22cb5b;
}

.wishlist_icon:hover path {
  stroke: white;
  fill: white;
}

.wishlist_icon:hover > g > g {
  stroke: #22cb5b;
  transition: stroke 0.8s ease;
}

/* cart icon style */

.cart_icon {
  color: white;
  transition: color 0.7s ease;
}

.cart_icon:hover {
  color: #22cb5b;
}

.cart_icon:hover path,
.cart_icon:hover circle {
  stroke: white;
}

.cart_icon:hover g > g:last-of-type circle,
.cart_icon:hover g > g:last-of-type path:last-of-type {
  fill: white;
}
