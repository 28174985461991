.animate {
  animation: bounce 0.5s none;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-8%);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateY(0);
    animation-timing-function: ease-in;
  }
}
