@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Exo 2", sans-serif;
}

* {
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swiper-pagination-bullet {
  opacity: 0.6 !important;
  background: white !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
  opacity: 1 !important;
}

/* Design for tooltip component */
.tippy-box {
  border-radius: 10px !important;
  max-width: 400px !important;
}

.grecaptcha-badge {
  display: none !important;
}

.testimonials-carousel .swiper-pagination-bullets .swiper-pagination-bullet {
  opacity: 0.6 !important;
  background: #828588 !important;
}

.testimonials-carousel
  .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  background: #22cb5b !important;
  opacity: 1 !important;
}

/* start of admin text editor */
.admin-text-editor *:not(table):not(td):not(tr):not(tbody):not(img) {
  all: revert;
}

.admin-text-editor li {
  white-space: normal !important;
}

.admin-text-editor table[Style] {
  border-width: revert;
}

.admin-text-editor table * {
  border-color: inherit;
  border-width: inherit;
}

/* horizontal scrollbar */
.horizontal-scroll::-webkit-scrollbar {
  margin-top: 10px;
  height: 6px;
  background: #f1f1f1;
  border-radius: 20px;
}
.horizontal-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #a8a8a8;
}
.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}

/* Toast Container */
.Toastify__toast-container {
  width: auto !important;
}

@media only screen and (min-width: 768px) {
  .Toastify__toast-container {
    max-width: 400px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .Toastify__toast-container {
    max-width: 450px !important;
  }
}

@media only screen and (min-width: 1280px) {
  .Toastify__toast-container {
    max-width: 500px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .Toastify__toast-container {
    max-width: 600px !important;
  }
}
