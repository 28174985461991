.opt_container div:first-child input {
  margin-left: 0px;
}

.singleInput {
  width: 2.5rem !important;
  height: 2.5rem;
  margin-left: 0.5rem;
  border: 1px solid #cbd0d6;
  border-radius: 10px;
  outline: none !important;
}

.singleInput:focus {
  outline: none !important;
  border: 1px solid #22cb5b;
  box-shadow: none;
}

@media screen and (min-width: 400px) {
  .singleInput {
    width: 2.85rem !important;
    height: 2.85rem;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 768px) {
  .singleInput {
    width: 3.5rem !important;
    height: 3.5rem;
    margin-left: 1.25rem;
  }
}
