.loader {
  width: 100px;
  height: 100px;
}
.loader path {
  fill: #22cb5b;
  transform-origin: center;
  animation: rotate 1.5s linear infinite;
}
.loaderreverse {
  animation-direction: reverse !important;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
  from {
    transform: rotate(0deg);
  }
}
